import React from 'react';
import HeaderVagas from './HeaderVagas';
import FooterVagas from './FooterVagas';
import Sobre2Vagas from './Sobre2Vagas';
// import FiltroVagas from './FiltroVagas';
import Vagas from './Vagas';
import '../../components/css/Vagas.css'; 

const KJRVagas = () => {
  return (
    <div className="vagas-container">
      <header>
      <HeaderVagas />
      </header>
      <main className='vagas-container-main'>
        <Sobre2Vagas />
        {/* <Filtro /> */}
        <Vagas />
      </main>
    <footer>
      <FooterVagas/>
    </footer>
    </div>
  );
};

export default KJRVagas;
