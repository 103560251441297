import React, { useState } from 'react';
import '../css/FaleConosco.css';
import emailjs from 'emailjs-com';

const FaleConosco = () => {
    const [email, setEmail] = useState('');
    const [duvida, setDuvida] = useState('');
    const [enviado, setEnviado] = useState(false);
    const [error, setError] = useState(null);
    const [isSending, setIsSending] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEnviado(false);
        setError(null);
    };

    const handleDuvidaChange = (event) => {
        setDuvida(event.target.value);
        setEnviado(false);
        setError(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSending(true);

        const templateParams = {
            email,
            duvida,
        };

        try {
            await emailjs.send(
                'service_1b4egvg',
                'template_gfvpg5z',
                templateParams,
                'ogCxq1TLPzdgKUj5x'
            );
            setEnviado(true);
            setEmail('');
            setDuvida('');
            setError(null);
        } catch (error) {
            console.error('Erro ao enviar o email:', error);
            setError('Erro ao enviar o email. Por favor, tente novamente.');
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className="fale-conosco">
            <form onSubmit={handleSubmit}>
                <div className="grid-container">
                    <div className="campoFale">
                        <label htmlFor="email">Fale conosco:</label>
                    </div>
                    <div className="campoFaleInput">
                        <div className="input-wrapper">
                            <input type="email" id="email" name="email" value={email} onChange={handleEmailChange} placeholder="Seu email" className="email-input" />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="campoDuvida">
                        <label htmlFor="duvida">Descreva sua dúvida:</label>
                    </div>
                    <div className="campoDuvidaInput">
                        <div className="input-wrapper">
                            <textarea id="duvida" name="duvida" value={duvida} onChange={handleDuvidaChange} placeholder="Sua dúvida"></textarea>
                        </div>
                    </div>
                </div>
                {email && duvida && (
                    <button type="submit" className="enviar-btn" disabled={isSending}>
                        {isSending ? 'Enviando, aguarde...' : 'Enviar'}
                    </button>
                )}
                {enviado && <div className="mensagem-enviada">Mensagem enviada!</div>}
                {error && <div className="mensagem-erro">{error}</div>}
            </form>
        </div>
    );
};

export default FaleConosco;