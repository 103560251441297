import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FiltroVagas from './FiltroVagas';
import ModalVagas from './ModalVagas';
import { FaBriefcase, FaGraduationCap, FaDollarSign } from 'react-icons/fa';
import '../../components/css/Vagas.css'; 

const Vagas = () => {
    const [vagas, setVagas] = useState([]);
    const [filteredVagas, setFilteredVagas] = useState([]);
    const [selectedVaga, setSelectedVaga] = useState(null);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(false);  

    useEffect(() => {
        const fetchVagas = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://kjr-vagas-back.vercel.app/api/vagas'); 
                setVagas(response.data);
                setFilteredVagas(response.data);
            } catch (error) {
                console.error('Erro ao buscar vagas:', error);
                setError(true); 
            } finally {
                setLoading(false);  
            }
        };
        fetchVagas();
    }, []);

    const handleFilter = ({ searchTerm, area, nivel, salario }) => {
      const filtered = vagas.filter((vaga) => {
        const matchesSearchTerm = vaga.titulo.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesArea = area === '' || vaga.area === area;
        const matchesNivel = nivel === '' || vaga.nivel === nivel;
        const matchesSalario = salario === '' || vaga.salario === salario;

        return matchesSearchTerm && matchesArea && matchesNivel && matchesSalario;
      });
      setFilteredVagas(filtered);
    };

    const handleOpenModal = (vaga) => {
      setSelectedVaga(vaga);
    };

    const handleCloseModal = () => {
      setSelectedVaga(null);
    };

    return (
      <div>
        <FiltroVagas onFilter={handleFilter} />

        {loading ? (
          <div className="loadingContainer">
            <div className="loadingText">Buscando vagas...</div>
            <div className="spinner"></div>
          </div>
        ) : error ? (
          <p>Erro ao buscar vagas, tente novamente mais tarde.</p>
        ) : (
          <div className="vagasContainer">
            {filteredVagas.length > 0 ? (
              filteredVagas.map((vaga) => (
                <div key={vaga._id} className="vagaItem" onClick={() => handleOpenModal(vaga)}>
                  <h2>{vaga.titulo}</h2>
                  <p className="empresa">{vaga.empresa}</p>
                  <div className="infoItem">
                    <FaBriefcase className="icon" /><span>{vaga.area}</span>
                  </div>
                  <div className="infoItem">
                    <FaGraduationCap className="icon" /><span>{vaga.nivel}</span>
                  </div>
                  <div className="infoItem">
                    <FaDollarSign className="icon" /><span>{vaga.salario}</span>
                  </div>
                  <button className="verBtn">Ver</button>
                </div>
              ))
            ) : (
              <p>Infelizmente, no momento não há vagas.</p>
            )}
          </div>
        )}

        {selectedVaga && (
          <ModalVagas vaga={selectedVaga} onClose={handleCloseModal} />
        )}
      </div>
    );
};

export default Vagas;
