import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import React from 'react';
import KJRDev from './js/KJRDev';
import KJRVagas from './components/js/KJRVagas';
// import Sobre from './js/Sobre';
// import TodosServicos from './js/TodosServicos.jsx';
import './global.css';
import './css/style.css';


const Layout = ({ children }) => {
    const location = useLocation();  

    const getClassForBackground = () => {
        if (location.pathname === '/') {
            return 'home-background'; 
        } else if (location.pathname === '/adsa-vagas') {
            return 'vagas-background';
        }
        return '';  
    };

    return (
        <div className={getClassForBackground()}>
            {children}
        </div>
    );
};

const App = () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<KJRDev />} />
                    <Route path="/adsa-vagas" element={<KJRVagas />} />
                </Routes>
            </Layout>
        </Router>
    );
};

export default App;