import React from 'react';
import styles from '../css/Carrossel.module.css';

const logos = [
    "/images/carrosselNew/html5.svg",
    "/images/carrosselNew/css.svg",
    "/images/carrosselNew/javascript.svg",
    "/images/carrosselNew/typescript.svg",
    "/images/carrosselNew/react.svg",
    "/images/carrosselNew/nodejs.svg",
    "/images/carrosselNew/docker.svg",
    "/images/carrosselNew/mongodb.svg",
    "/images/carrosselNew/mysql.svg",
    // "/images/carrosselNew/pbi2.svg",
    "/images/carrosselNew/postgresql.svg",
    "/images/carrosselNew/python.svg",
    "/images/carrosselNew/django.svg",
    "/images/carrosselNew/aws.svg",
    "/images/carrosselNew/redis.svg",
    "/images/carrosselNew/scrum.svg",
    "/images/carrosselNew/dotnet.svg",
    "/images/carrosselNew/googleads.svg",
    "/images/carrosselNew/meta.svg",
    "/images/carrosselNew/gitforwindows.svg",
    "/images/carrosselNew/github.svg",
    "/images/carrosselNew/gitlab.svg",
    "/images/carrosselNew/linux.svg",
    "/images/carrosselNew/redhat.svg",
    "/images/carrosselNew/fastify.svg",
    "/images/carrosselNew/vitest.svg",
    "/images/carrosselNew/flask.svg",
    "/images/carrosselNew/prisma.svg",
    
];

const Carrossel = () => {
    return (
        <div className={styles.carrossel}>
            <div className={styles.carrosselTrack}>
                {logos.concat(logos).map((logo, index) => (
                    <div key={index} className={styles.carrosselItem}>
                        <img src={logo} alt="Logo" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carrossel;
