import React from 'react';
import '../../components/css/HeaderVagas.css'; 
import logo from '../../assets/images/logoazul.svg';

const HeaderVagas = () => {
  return (
    <header className="headerVagas">
      <div className="headerVagas-left">
        {/* <p>Em busca do sucesso</p> */}
      </div>
      <div className="headerVagas-center">
        <img src={logo} alt="Logo" className="headerVagas-logo" />
      </div>
      <div className="headerVagas-right">
        {/* <p>Jesus é Top</p> */}
      </div>
    </header>
  );
};

export default HeaderVagas;
