import React, { useRef, useState, useEffect } from 'react';
import '../css/style.css';
import Footer from './Footer';
import FaleConosco from './FaleConosco';
import Servicos from './Servicos';
import Projetos from './Projetos';
// import Equipe from './Equipe';
import Carrossel from './Carrossel';
import Depoimentos from './Depoimentos';

const KJRDev = () => {
    const servicosRef = useRef(null);
    const projetosRef = useRef(null);
    const contatosRef = useRef(null);
    // const equipeRef = useRef(null);
    const depoimentosRef = useRef(null);
    const [isLogoFixed, setIsLogoFixed] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const logoRef = useRef(null);
    const menuIconRef = useRef(null);
    const whatsappLogoRef = useRef(null);

    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsLogoFixed(true);
            document.body.classList.add('scrolled'); 
        } else {
            setIsLogoFixed(false);
            document.body.classList.remove('scrolled');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
        if (!isMenuOpen) {
            menuIconRef.current.classList.add('hidden');
            whatsappLogoRef.current.classList.add('hidden');
            menuIconRef.current.classList.add('black');
        } else {
            menuIconRef.current.classList.remove('hidden');
            whatsappLogoRef.current.classList.remove('hidden');
            menuIconRef.current.classList.remove('black');
        }
    };

    return (
        <div>
            <header id="main-header" className={`${isLogoFixed ? 'fixed-logo' : ''}`}>
                <button className={`menu-icon ${isMenuOpen ? 'black' : ''}`} onClick={handleMenuClick} ref={menuIconRef}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                <div>
                    <img src="/images/logo.svg" alt="Logo" className="logoHeader" ref={logoRef} />
                </div>
            </header>
            <main>
                <nav className={`menu ${isMenuOpen ? 'open' : ''}`}>
                    <div className="menu-container">
                        <button className="close-button" onClick={handleMenuClick}>X</button>
                        <ul>
                            <li><a href="#home" onClick={() => { setIsMenuOpen(false); scrollToTop(); }}>Home</a></li>
                            <li><a href="#servicos" onClick={() => { setIsMenuOpen(false); scrollToSection(servicosRef); }}>Serviços</a></li>
                            <li><a href="#projetos" onClick={() => { setIsMenuOpen(false); scrollToSection(projetosRef); }}>Projetos</a></li>
                            {/* <li><a href="#equipe" onClick={() => { setIsMenuOpen(false); scrollToSection(equipeRef); }}>Equipe</a></li> */}
                            <li><a href="#depoimentos" onClick={() => { setIsMenuOpen(false); scrollToSection(depoimentosRef); }}>Depoimentos</a></li>
                            <li><a href="#contatos" onClick={() => { setIsMenuOpen(false); scrollToSection(contatosRef); }}>Contatos</a></li>
                            <li><a href="/adsa-vagas" target="_blank" onClick={() => setIsMenuOpen(false)}>ADSA - Vagas</a></li>
                            {/* <li>
                                <button onClick={() => { setIsMenuOpen(false); navigate('/adsa-vagas'); }}>
                                    ADSA - Vagas
                                </button>
                            </li> */}
                        </ul>
                    </div>
                </nav>
                <div className="hero">
                    <h1>
                        Empresa de Tecnologia<br/>
                        <span className="highlight">
                        <span className="word subtitle-highlight">Convertendo sonhos e ideias em realidade</span>
                        </span>
                    </h1>
                    <p className="subtitle">
                        <span className="subtitle-line">Especialistas em</span>
                        <span className="subtitle-highlight">desenvolvimento moderno e escalável</span>
                    </p>
                    <a href="#servicos" className="btn">
                        <span>Impulsione seu negócio</span>
                        <div className="btn-effect"></div>
                    </a>
                </div>
                <div className='carrossel-component'>
                    <Carrossel />
                </div>
                <div ref={servicosRef} id="servicos">
                    <Servicos />
                </div>
                <div ref={projetosRef} id="projetos">
                    <Projetos />
                </div>
                {/* <div ref={equipeRef} id="equipe">
                    <Equipe />
                </div> */}
                <div ref={depoimentosRef} id="depoimentos">
                    <Depoimentos />
                </div>
                <div ref={contatosRef} id="contatos">
                    <FaleConosco />
                </div>
                <footer>
                    <Footer />
                </footer>
            </main>
            <div className="whatsapp-logo" ref={whatsappLogoRef}>
                <a href="https://api.whatsapp.com/send?phone=5511945635306" target="_blank" rel="noopener noreferrer">
                    <img src="/images/whatsVerde.svg" alt="WhatsApp Logo" className="whatsapp-icon" />
                </a>
            </div>
            <div id="scrollToTop" className="scroll-to-top" onClick={scrollToTop}>
                <img src="/images/seta.svg" alt="Seta" className="seta-icon" />
            </div>
        </div>
    );
};

export default KJRDev;